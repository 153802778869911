import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { Container, Row, Col } from 'react-bootstrap'
import Layout from '../components/layout'
import Banner from '../components/banner'
import Seo from '../components/seo'

import PrivacySeoImage from '../assets/images/direct-patient-care.jpg'

class Privacy extends React.Component {
    render() {
        return (
            <StaticQuery
            query={graphql`
                query PrivacyMeta {
                    site {
                        siteMetadata {
                            title
                            description
                        }
                    }
                }
            `}
            render={data => (
                <>
                <Layout page='contact'>
                    <Seo title={`Privacy Policy | ${data.site.siteMetadata.title}`} description={`${data.site.siteMetadata.description}`} image={PrivacySeoImage} />
                    <Banner title={`Privacy Policy`} subTitle='' titleColor='#FFFFFF' subTitleColor='#FFFFFF'  />
                    <section id='content'>
                        <Container>
                            <Row>
                                <Col xs='12' sm='12' md='12' lg='12'>
                                    <h2>Privacy Policy</h2>

                                    <p>Protecting your private information is our priority. This Statement of Privacy applies to Optimal Health &amp; Wellness [OHW], this website, and governs data collection and usage. For the purposes of this Privacy Policy, unless otherwise noted, all references to Optimal Health &amp; Wellness, including the website and OHW. By using the OHW website, you consent to the data practices described in this statement.</p>
                                    
                                    <h3>Collection of your personal information</h3>

                                    <p>In order to better provide you with products and services offered, OHW may collect personally identifiable information, such as your first and last name, email address, and phone number.</p>

                                    <p>We do not collect any personal information about you unless you voluntarily provide it to us. However, you may be required to provide certain personal information to us when you elect to use certain products or services. These may include: (a) registering for an account; (b) entering a sweepstakes or contest sponsored by us or one of our partners; (c) signing up for special offers from selected third parties; (d) sending us an email message; (e) submitting your credit card or other payment information when ordering and purchasing products and services. To wit, we will use your information for, but not limited to, communicating with you in relation to services and/or products you have requested from us. We also may gather additional personal or non-personal information in the future.</p>

                                    <h3>Use of your personal information</h3>

                                    <p>OHW collects and uses your personal information to operate and deliver the services you have requested.</p>

                                    <p>OHW  may also use your personally identifiable information to inform you of other products or services available from OHW and its affiliates.</p>

                                    <h3>Sharing information with third parties</h3>

                                    <p>OHW does not sell, rent or lease its customer lists to third parties.</p>

                                    <p>OHW may share data with trusted partners to help perform statistical analysis, send you email or postal mail, provide customer support, or arrange for deliveries. All such third parties are prohibited from using your personal information except to provide these services to OHW, and they are required to maintain the confidentiality of your information.</p>

                                    <p>OHW may disclose your personal information, without notice, if required to do so by law or in the good faith belief that such action is necessary to: (a) conform to the edicts of the law or comply with legal process served on OHW or the site; (b) protect and defend the rights or property of OHW; and/or (c) act under exigent circumstances to protect the personal safety of users of OHW, or the public.</p>

                                    <h3>Tracking user behavior</h3>

                                    <p>OHW may keep track of the websites and pages our users visit within the site, in order to determine what OHW services are the most popular. This data is used to deliver customized content and advertising within OHW to patients whose behavior indicates that they are interested in a particular subject area.</p>

                                    <h3>Automatically collected information</h3>

                                    <p>Information about your computer hardware and software  may be automatically collected by OHW. This information can include: your IP address, browser type, domain names, access times and referring website addresses. This information is used for the operation of the service, to maintain quality of the service, and to provide general statistics regarding use of the OHW website.</p>

                                    <h3>Cookies</h3>

                                    <p>The OHW website may use "cookies" to help you personalize your online experience. A cookie is a text file that is placed on your hard disk by a web page server. Cookies cannot be used to run programs or deliver viruses to your computer. Cookies are uniquely assigned to you, and can only be read by a web server in the domain that issued the cookie to you.</p>

                                    <p>One of the primary purposes of cookies is to provide a convenience feature to save you time. The purpose of a cookie is to tell the Web server that you have returned to a specific page. For example, if you personalize pages, or register with /OHW site or services, a cookie helps OHW to recall your specific information on subsequent visits. This simplifies the process of recording your personal information, such as billing addresses, shipping addresses, and so on. When you return to the same OHW website, the information you previously provided can be retrieved, so you can easily use the site features that you customized.</p>

                                    <p>You have the ability to accept or decline cookies. Most Web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. If you choose to decline cookies, you may not be able to fully experience the interactive features of the OHW services or website you visit.</p>

                                    <h3>Security of your personal information</h3>

                                    <p>OHW secures your personal information from unauthorized access, use, or disclosure. OHW uses the following methods for the purposes of SSL protocol. When personal information (such as a credit card number) is transmitted to other websites, it is protected through the use of encryption, such as the Secure Sockets Layer (SSL) protocol.</p>

                                    <p>We strive to take appropriate security measures to protect against unauthorized access to or alteration of your personal information. Unfortunately, no data transmission over the Internet or any wireless network can be guaranteed to be 100% secure. As a result, while we strive to protect your personal information, you acknowledge that: (a) there are security and privacy limitations inherent to the Internet which are beyond our control; and (b) security, integrity, and privacy of any and all information and data exchanged between you and us through this Site cannot be guaranteed.</p>

                                    <h3>Email communications</h3>

                                    <p>From time to time, OHW may contact you via email for the purpose of providing announcements, promotional offers, alerts, confirmations, surveys, and/or other general communication. In order to improve our Services, we may receive a notification when you open an email from OHW, or click on a link therin.</p>

                                    <p>If you would like to stop receiving marketing or promotional communications via email from OHW, you may opt out of such communications by <a href='mailto:info@optimalhealthwellnessnj.com'>info@optimalhealthwellnessnj.com</a>.</p>

                                    <h3>Changes to this statement</h3>

                                    <p>OHW reserves the right to change this Privacy Policy from time to time. We will notify you about significant changes in the way we treat personal information by sending a notice to the primary email address specified in your account, by placing a prominent notice on our website, and/or by updating any privacy information. Your continued use of the website and/or Services available after such modifications will constitute your: (a) acknowledgment of the modified Privacy Policy; and (b) agreement to abide and be bound by that Policy.</p>

                                    <p><em>Effective as of November 3, 2021.</em></p>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                </Layout>
                </>
            )}
            />
        )
    }
}

export default Privacy